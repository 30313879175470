'use client';

import { Center, Flex, Loader, Title } from '@mantine/core';
import { useQuery } from '@urql/next';
import { shallowEqual, useSelector } from '@xstate/react';
import { Suspense, startTransition, useContext, useEffect, useMemo } from 'react';
import { AccountsFilterContext, AccountsFilterProvider, ProfilesListHeader, SearchResultPageInnerDesktop, SearchResultPageInnerMobile, SearchResultPageOuterDesktop, SearchResultPageOuterMobile } from '~/app/(accounts)/_components';
import { AppLayoutContext, ScrollFetcher } from '~/app/_components';
import { ProfessionsFilterProvider } from './_components/ProfessionsFilterContext';
import { RelayPaginationContext, RelayPaginationProvider } from './_components/RelayPaginationContext';
import { GetPaginatedAccounts } from './_lib/GetPaginatedAccounts';
const isSSR = typeof window === 'undefined';
function SearchResultPage({
  page
}: {
  page: number;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    searchQuery
  } = useContext(AccountsFilterContext);
  const {
    actor
  } = useContext(RelayPaginationContext);
  const variables = useSelector(actor, s => s.context.pageArgsList[page], shallowEqual);
  const updatedVariables = {
    ...variables,
    query: searchQuery
  };
  const [{
    data,
    fetching,
    error
  }] = useQuery({
    query: GetPaginatedAccounts,
    variables: updatedVariables,
    pause: !variables || isSSR
  });
  const pageInfo = data?.accounts.pageInfo;
  useEffect(() => {
    if (pageInfo) {
      startTransition(() => {
        actor.send({
          type: 'UPDATE_PAGE_INFO',
          pageNumber: page,
          pageInfo,
          totalCount: data?.accounts.totalCount
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo]);

  // TODO this needs to be moved to suspense
  // also the errors need to be handled better (network vs backend errors)
  // + automatic retries need to be implemented
  if (error) return <Flex style={{
    height: 'calc(100vh - 70px)'
  }}>
        <Center>
          <Loader size={50} />
        </Center>
      </Flex>;
  if (fetching || !data) return <Flex w="100vw" h="calc(100vh - 50px)" justify="center"
  // align="center"
  // style={{
  //   height: 'calc(100vh - 70px)',
  // }}
  >
        <Loader size={mobile ? 130 : 120} mt={mobile ? '220px' : '50px'} />
      </Flex>;
  const Inner = mobile ? SearchResultPageInnerMobile : SearchResultPageInnerDesktop;
  return mobile ? <div style={{
    minHeight: 'calc(100vh - 70px - 62px)'
  }} data-sentry-component="SearchResultPage" data-sentry-source-file="page.tsx">
      <Inner edges={data.accounts.edges} data-sentry-element="Inner" data-sentry-source-file="page.tsx" />
    </div> : <Inner edges={data.accounts.edges} data-sentry-element="Inner" data-sentry-component="SearchResultPage" data-sentry-source-file="page.tsx" />;
}
function AccountSearchResultsInner() {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    actor
  } = useContext(RelayPaginationContext);
  const pageArgsList = useSelector(actor, state => state.context.pageArgsList, shallowEqual);
  const pages = useMemo(() => Object.keys(pageArgsList).map(Number), [pageArgsList]);
  const Outer = mobile ? SearchResultPageOuterMobile : SearchResultPageOuterDesktop;

  // WARNING
  // the suspense around SearchResultPage is required
  // otherwise the higher level suspense will be triggered
  // and the scroll will jump
  return <>
      {mobile ? null : <Title order={2} px="100px" size="40px" fw={700} lts="-2px">
          COMMUNITY
        </Title>}

      <ProfilesListHeader data-sentry-element="ProfilesListHeader" data-sentry-source-file="page.tsx" />
      <Outer data-sentry-element="Outer" data-sentry-source-file="page.tsx">
        <ScrollFetcher id="accounts-search-results" data-sentry-element="ScrollFetcher" data-sentry-source-file="page.tsx">
          <>
            {pages.map(page => <Suspense key={page}>
                <SearchResultPage page={page} key={page} />
              </Suspense>)}
          </>
        </ScrollFetcher>
      </Outer>
    </>;
}
export default function AccountSearchResults() {
  return <ProfessionsFilterProvider data-sentry-element="ProfessionsFilterProvider" data-sentry-component="AccountSearchResults" data-sentry-source-file="page.tsx">
      <RelayPaginationProvider id="accounts" data-sentry-element="RelayPaginationProvider" data-sentry-source-file="page.tsx">
        <AccountsFilterProvider data-sentry-element="AccountsFilterProvider" data-sentry-source-file="page.tsx">
          <AccountSearchResultsInner data-sentry-element="AccountSearchResultsInner" data-sentry-source-file="page.tsx" />
        </AccountsFilterProvider>
      </RelayPaginationProvider>
    </ProfessionsFilterProvider>;
}